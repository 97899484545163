import "./bootstrap-config";
import "./font-awesome-config";
import "../styles/main.scss";
import "aos/dist/aos.css";
import "./swiper-config";
import "./full_menu";
// import "./icon-fade";


// Uncomment to add Fancybox light box library
// Import Fancybox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Initialize Fancybox
document.addEventListener("DOMContentLoaded", () => {
  Fancybox.bind("[data-fancybox]", {
    // Add any custom options here
  });
});

import "./theme";