document.addEventListener('DOMContentLoaded', function() {
    const menuContentFill = document.querySelector('.menu-content-fill');
    const mainMenu = document.querySelector('.mobile-main-menu');
    let currentLevel = [];

    // Function to create back button
    function createBackButton(backText) {
        const backButton = document.createElement('button');
        backButton.innerHTML = '<i class="fa-regular fa-chevron-left pe-3"></i> ' + backText;
        backButton.classList.add('menu-back-button');
        backButton.addEventListener('click', goBack);
        return backButton;
    }

    // Function to go back to previous menu level
    function goBack() {
        if (currentLevel.length > 1) {
            currentLevel.pop();
            updateMenuContent(currentLevel[currentLevel.length - 1]);
        } else {
            resetMenu();
        }
    }

    // Function to reset menu to initial state
    function resetMenu() {
        menuContentFill.innerHTML = '';
        mainMenu.style.display = 'block';
        menuContentFill.style.display = 'none'; // Hide menu-content-fill
        currentLevel = [];
    }

    // Function to update menu content
    function updateMenuContent(content, backText) {
        menuContentFill.innerHTML = '';
        menuContentFill.appendChild(createBackButton(backText));
        menuContentFill.appendChild(content);
        menuContentFill.style.display = 'block'; // Show menu-content-fill
    }

    // Add click event listeners to menu items with children
    document.querySelectorAll('.mobile-main-menu li.menu-item-has-children > a').forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            const parentLi = this.parentElement;
            const parentText = this.textContent.trim();
            const subMenu = parentLi.querySelector('.sub-menu').cloneNode(true);
            
            if (currentLevel.length === 0) {
                mainMenu.style.display = 'none';
                menuContentFill.style.display = 'block'; // Show menu-content-fill
            }
            
            currentLevel.push(subMenu);
            updateMenuContent(subMenu, parentText);
            
            // Add active class to the parent li
            parentLi.classList.add('active');
        });
    });

    // Add event listener for Escape key
    document.addEventListener('keydown', function(e) {
        if (e.key === 'Escape') {
            if (currentLevel.length > 0) {
                goBack();
            } else {
                document.getElementById('full-menu').close();
            }
        }
    });

    // Initially hide menu-content-fill
    menuContentFill.style.display = 'none';
});
