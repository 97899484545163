/******** Theme Scripts *********/
import AOS from "aos";
AOS.init({
  duration: 1000,
  once: false,
  mirror: true,
});

/**
 * Represents a collection of h1 elements.
 * 
 * @type {NodeList.<HTMLHeadingElement>}
 */
const h1Elements = document.querySelectorAll('h1');

/**
 * Replaces all h1 elements (except the first one) with div elements of class 'display-large'.
 * This maintains the visual hierarchy while adjusting the semantic structure.
 */
h1Elements.forEach((h1, index) => {
  if (index === 0) {
    return;
  }
  const div = document.createElement('div');
  div.className = 'display-large';
  div.innerHTML = h1.innerHTML;
  div.style.cssText = h1.style.cssText;
  div.classList.add(...h1.classList);
  h1.replaceWith(div);
});



/**
 * Calculates the contrast color (black or white) based on the given hex color.
 *
 * @param {string} hexColor - The hex color code.
 * @returns {string} The contrast color ('#000' for black or '#fff' for white).
 */
const getContrastYIQ = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  console.log(yiq);
  return yiq >= 130 ? '#000' : '#fff';
};



/**
 * Converts an RGB color string to its hexadecimal representation.
 *
 * @param {string} rgb - The RGB color string (e.g., "rgb(255, 255, 255)").
 * @returns {string} The hexadecimal color code.
 */
const rgbToHex = (rgb) => {
  const [r, g, b] = rgb.match(/\d+/g);
  const hex = ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  return `#${hex}`;
};



/**
 * Sets the text color of all elements inside the given app based on its background color.
 * 
 * @param {HTMLElement} unique_id - The unique identifier of the app.
 * @returns {void}
 */
const setTextColorBasedOnBackground = (unique_id) => {
  const app = unique_id;
  const color = app.style.backgroundColor;
  if (!color) {
    return;
  }
  const hexColor = rgbToHex(color);

  const textColor = getContrastYIQ(hexColor);
  
  const elements = app.querySelectorAll('*');
  const exempt = ['button', 'a', 'input', 'select', 'textarea', 'h6', 'span'];
  elements.forEach(element => {
    if (exempt.includes(element.tagName.toLowerCase())) {
      return;
    }
    element.style.color = textColor;
  });
}



/**
 * Applies text color based on background for all column modules.
 */
const columnModules = document.querySelectorAll('.column-module');
columnModules.forEach(columnModule => {
  setTextColorBasedOnBackground(columnModule);
});


/**
 * Represents a collection of accordion items.
 * @type {NodeList}
 */
const accordionItems = document.querySelectorAll('.accordion-item');
accordionItems.forEach(item => {
    item.addEventListener('click', function (event) {
        // Check if the click occurred within .accordion-content
        if (!event.target.closest('.accordion-content')) {
            // Toggle active class on the clicked item
            item.classList.toggle('active');
            // Close other items
            accordionItems.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.classList.remove('active');
                }
            });
            // Scroll to the active item with an offset of about 100px
            if (item.classList.contains('active')) {
                const yOffset = -300; // 300px offset
                const y = item.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
    }
  });
});



/**
 * Initializes Swiper for swiper containers if they exist.
 */
const swiperContainers = document.querySelectorAll('.swiper-container');
if (swiperContainers.length > 0) {
  import('./swiper-init');
}



/**
 * Video Testimonials
 */

document.addEventListener('DOMContentLoaded', function() {
  const videoWrappers = document.querySelectorAll('.video-wrapper');

  videoWrappers.forEach(wrapper => {
    const video = wrapper.querySelector('video');
    if (!video) {
      return;
    }
    const playButton = wrapper.querySelector('.play-button');
    if (!playButton) {
      return;
    }

    playButton.addEventListener('click', function() {
      video.controls = true;
      video.play();
      playButton.style.display = 'none';
    });

    video.addEventListener('play', function() {
      video.controls = true;
      playButton.style.display = 'none';
    });

    video.addEventListener('pause', function() {
      if (video.ended) {
        video.controls = false;
      }
      playButton.style.display = 'block';
    });

    video.addEventListener('ended', function() {
      video.controls = false;
      playButton.style.display = 'block';
    });

    // Hide controls when mouse leaves video area
    wrapper.addEventListener('mouseleave', function() {
      if (!video.paused) {
        video.controls = false;
      }
    });

    // Show controls when mouse enters video area
    wrapper.addEventListener('mouseenter', function() {
      if (!video.paused) {
        video.controls = true;
      }
    });
  });
});





